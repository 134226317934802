<template>
  <div v-if="category">
    <v-breadcrumbs :items="breadcrumbItems"/>
    <h1 class="header">
      {{category.name}}
    </h1>
    <p>Select a nutrient to exlore common sources of each nutrient or recipes rich in a certain nutrient.</p>

    <h3 class="headline mt-4">Key {{category.name | lowercase}}</h3>
    <NutrientGrid :items="primaryNutrients" />

    <h3 class="headline mt-4">Other {{category.name | lowercase}}</h3>
    <v-list color="blue-grey lighten-5">
        <template v-for="(nutrient, i) in secondaryNutrients">
          <v-list-item :key="i" :to="{name: 'nutrient', params: {id: nutrient.id}}">
            <v-list-item-content>
              <v-list-item-title v-html="nutrient.name"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon small>
                <v-icon color="grey darken-1">fas fa-chevron-right</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider
            v-if="i + 1 < secondaryNutrients.length"
            :key="i + 'sep'"
          ></v-divider>
        </template>
      </v-list>    

    <div v-if="category.subCategories && category.subCategories.length">
      <h3 class="headline mt-4">Subcategories</h3>
      <p>Explore nutrients by their category.</p>
      <GenericCardGrid :items="category.subCategories" v-slot="slotProps">
        <v-card color="secondary" dark :to="{name: 'nutrientCategory', params: {id: slotProps.item.id}}">
          <v-card-title>
            {{slotProps.item.name}}
          </v-card-title>
        </v-card>
      </GenericCardGrid>
    </div>

  </div>
</template>

<script>
import NutrientGrid from "@/components/NutrientGrid.vue";
import ArticleGrid from "@/components/ArticleGrid.vue";
import GenericCardGrid from "@/components/GenericCardGrid.vue";
import nutrientService from "@/services/nutrientService";
import articleService from "@/services/articleService";

export default {
  name: "NutrientCategory",
  components: {
    NutrientGrid,
    ArticleGrid,
    GenericCardGrid,
  },
  props: {
  },
  data() {
    return {
      category: null,
      articles: null,
    };
  },
  async mounted() {
    this.articles = null;
    await this.loadData(this.$route.params.id);
  },
  beforeRouteUpdate (to, from, next) {
    // react to route changes...
    this.loadData(to.params.id);
    next();
  },
  methods: {
    async loadData(id) {
      var result = await nutrientService.getCategory(id);
      this.category = result.data;
    }
  },
  computed: {
    breadcrumbItems() {
      return this.category == null ? null : [
        { text: 'Nutrients', href: '/nutrients' },
        this.category.parent == null ? null : { text: this.category.parent.name, to: {name: 'nutrientCategory', params: {id: this.category.parent.id}} },
        { text: this.category.name, disabled: true },
      ].filter(x =>x != null);
    },
    primaryNutrients() {
      return this.category.nutrients.filter(x => x.primary);
    },
    secondaryNutrients() {
      return this.category.nutrients.filter(x => !x.primary);
    },

  }
};
</script>
